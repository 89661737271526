import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useShop } from '../../graphql/static-hooks/useShop';

export const SEO = ({
  description,
  lang,
  meta,
  title,
}) => {
  const shop = useShop();
  const desc = description || shop.description;

  return (
    <Helmet
      defaultTitle={shop.name}
      title={title}
      titleTemplate={`%s - ${shop.name}`}
      meta={[
        {
          name: 'description',
          content: desc,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: desc,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: shop.name,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: desc,
        },
      ].concat(meta)}
    >
      <html lang={lang} />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: undefined,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
